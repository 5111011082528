import { useMemo, useEffect, useState, useLayoutEffect } from "react";

import useClinicService from "service-hooks/useClinicService";
import Header from "blikol-layouts/components/Header";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

import "./style.css";

//rsuite components
import { DateRangePicker, Stack } from "rsuite";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { InputAdornment } from "@mui/material";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonPagination from "components/ArgonPagination";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import tableData from "layouts/pages/users/reports/data/tableData";

import "utils/i18n"
import { useTranslation } from "react-i18next";

function DataTable({
  entriesPerPage,
  showEntriesPerPage,
  hasTabHeader,
  tabList,
  onTabChangeValue,
  canSearch,
  hasStatistics,
  statValues,
  totalEntries,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  setSearch,
  search,
  dataTableName,
  canExportCSV,
  canAdd,
  canFilter,
  dateFilter,
  onDateRangeChange,
  filters,
  setPageNo,
  pageNo,
  totalPages,
  setEntriesPerPage,
  lastFixed,
  viewEntry,
  clickableRecords,
}) {
  const [defaultSortState, setDefaultSortState] = useState({ id: null, isDesc: null })
  const defaultValue = entriesPerPage ? entriesPerPage : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [10, 20, 30, 40, 50];
  const data = useMemo(() => table.rows, [table]);
  const pageOptions = Array.from({ length: totalPages }, (_, index) => index + 1);
  const [tabVal, setTabVal] = useState();
  const styleHeader = {
    width: "118.5px",
    color: "#FFF",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    borderRadius: "4px",
    boxShadow: "0px 2px 5.5px 0px rgba(0, 0, 0, 0.06)",
  };
  const handleDateRangeChange = (newDateRange) => {
    onDateRangeChange(newDateRange);
  };

  const { t } = useTranslation()

  const columns = useMemo(() => {
    return table.columns.map(column => ({
      ...column,
    }));
  }, [table]);


  const tableInstance = useTable(
    {
      columns, data,
      // initialState: { page: 0, sortBy: [{ id: localStorage.getItem('sortField'), desc: localStorage.getItem('sortOrder') }] } 
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, page, setPageSize } =
    tableInstance;

  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const getPaginationItems = (currentPage, totalPages) => {
    const delta = 2; // Number of pages to show around the current page
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - delta && i <= currentPage + delta)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const renderPagination = getPaginationItems(pageNo, totalPages).map((option, index) => (
    <ArgonPagination
      item
      key={index}
      onClick={() => typeof option === "number" && setPageNo(option)}
      active={pageNo === option}
      disabled={typeof option !== "number"}
    >
      {option}
    </ArgonPagination>
  ));

  const getToggleSortProps = (column) => ({
    ...column.getSortByToggleProps(),
    onClick: (e) => {
      e.preventDefault(); // Prevent default link behavior
      column.toggleSortBy(column.isSortedDesc ? false : true);
    }
  });


  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    const col_name = column.id
    const check = column.isSortedDesc
    if (sortedValue !== "none") {
      const value = sortedValue == "desc"
      localStorage.setItem('sortField', col_name)
      localStorage.setItem('sortOrder', sortedValue === 'desc' ? true : false)

    }
    return sortedValue;
  };

  const handleClearSearch = () => {
    setSearch("");
    setPageNo(1);
  };

  const formatOptionLabel = (option) => {
    // Remove underscores and dashes, capitalize each word, and join with space
    return option
      .replace(/[_-]/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  function lowerFirstLetter(word) {
    if (typeof word !== "string" || word.length === 0) {
      return word;
    }
    return word.charAt(0).toLowerCase() + word.slice(1);
  }

  const handleTabChange = (selectedTab) => {
    selectedTab = lowerFirstLetter(selectedTab);
    onTabChangeValue(selectedTab);
  };

  // Setting the entries starting point
  const entriesStart = pageNo === 0 ? pageNo : pageNo * entriesPerPage + 1 - entriesPerPage;

  // Setting the entries ending point
  let entriesEnd = pageNo * entriesPerPage < totalEntries ? pageNo * entriesPerPage : totalEntries;
  return (
    <>
      <ArgonBox px={4} pt={4} display={"flex"} justifyContent={"space-between"}>
        {dataTableName && (
          <ArgonBox>
            <ArgonTypography
              width={"auto"}
              sx={{
                color: "var(--wireframes-primary, var(--Body, #373D3F))",
                fontFamily: "Open Sans",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "28px",
              }}
            >
              {t(dataTableName)}
            </ArgonTypography>
          </ArgonBox>
        )}
        <ArgonBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", position: "relative" }}>
          {canSearch && (
            <ArgonBox sx={{ marginRight: "16px", width: "251px" }}>
              <TextField
                type="text"
                id="search"
                name="search"
                value={search} // Ensure that the value is controlled by the `search` state
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPageNo(1);
                }}
                sx={{ paddingLeft: "0px !important" }}
                placeholder={t("Search here")}
                variant="outlined"
                fullWidth
                size="medium"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: search && (
                    <InputAdornment position="end">
                      <ArgonButton
                        variant="text"
                        onClick={handleClearSearch}
                        sx={{
                          position: 'absolute',
                          right: '16px',
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                          marginRight: "-30px"

                        }}
                      >
                        <ArgonBox>
                          <svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill="#000000" />
                          </svg>
                        </ArgonBox>
                      </ArgonButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ArgonBox>
          )}
          {canExportCSV && (
            <ArgonBox>
              <ArgonButton
                variant="outlined"
                color="info"
                sx={{
                  borderRadius: "4px",
                  border: "1px solid #0A9CC4",
                  marginRight: "16px",
                  width: "fit-content",
                }}
                onClick={canExportCSV.action}
              >
                {t(canExportCSV.name)}
              </ArgonButton>
            </ArgonBox>
          )}
          {canAdd && (
            <ArgonBox>
              <ArgonButton
                variant="contained"
                color="info"
                sx={{
                  borderRadius: "4px",
                }}
                onClick={canAdd.action}
              >
                {t(canAdd.name)}
              </ArgonButton>
            </ArgonBox>
          )}
        </ArgonBox>
      </ArgonBox>
      {entriesPerPage || canSearch ? (
        <>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center" py={4} px={2}>
            {showEntriesPerPage && (
              <ArgonBox display="flex" alignItems="center">
                <ArgonBox width="25%">
                  <ArgonSelect
                    defaultValue={{ value: defaultValue, label: defaultValue }}
                    options={entries.map((entry) => ({ value: entry, label: entry }))}
                    onChange={({ value }) => {
                      setEntriesPerPage(value);
                      setPageNo(1); // Reset to page 1
                    }}
                    size="medium"
                    sx={{
                      display: "flex",
                      padding: "9px 12px",
                      alignItems: "center",
                      height: "36px",
                    }}
                  />
                </ArgonBox>
                <ArgonTypography
                  variant="caption"
                  color="secondary"
                  sx={{
                    color: "var(--wireframes-primary, var(--Body, #373D3F))",
                    fontFamily: "Open Sans",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "22px",
                  }}
                >
                  &nbsp;&nbsp; {t("of")} {totalEntries} {t("entries")}
                </ArgonTypography>
              </ArgonBox>
            )}
            {hasTabHeader && (
              <Grid container spacing={3} alignItems="center" mb={1} sx={{ width: "70%" }}>
                <Grid item xs={12} md={7} lg={7}>
                  <Header
                    onTabChange={handleTabChange}
                    numTabs={2}
                    tabNames={tabList}
                    customStyle={styleHeader}
                  />
                </Grid>
              </Grid>
            )}
            {canFilter && (
              <ArgonBox display={"flex"} mr={2}>
                <ArgonBox>
                  {dateFilter && (
                    // Date filter implementation
                    <div style={{ marginLeft: "16px" }}>
                      <DateRangePicker
                        placeholder={t("Apply date filter")}
                        showOneCalendar
                        style={{ width: 250 }}
                        size="lg"
                        format="dd.MM.yyyy"
                        onShortcutClick={(shortcut, event) => {
                          handleDateRangeChange(shortcut.value);
                          setPageNo(1);
                        }}
                        onOk={(shortcut) => {
                          handleDateRangeChange(shortcut);
                          setPageNo(1);
                        }}
                        onClean={(shortcut) => {
                          handleDateRangeChange("");
                          setPageNo(1);
                        }}
                        ranges={[]}
                      />
                    </div>
                  )}
                </ArgonBox>
                {filters && filters.length > 0 && (
                  <>
                    {filters?.map((filter, index) => (
                      <div key={index} style={{ marginLeft: "16px" }}>
                        <ArgonSelect
                          id={`filterSelect${index}`}
                          size="medium"
                          options={
                            filter.listOfOptions
                              ? [
                                ...filter.listOfOptions.map((option) => {
                                  if (typeof option === "string") {
                                    return {
                                      label: formatOptionLabel(option),
                                      value: option,
                                    };
                                  } else if (
                                    typeof option === "object" &&
                                    option.hasOwnProperty("value") &&
                                    option.hasOwnProperty("label")
                                  ) {
                                    return option;
                                  } else {
                                    return { label: option.label, value: option.value };
                                  }
                                }),
                              ]
                              : []
                          }
                          placeholder={t(filter.name)}
                          onChange={(e) => {
                            filter.action(e?.value ?? "");
                            setPageNo(1);
                          }}
                          isClearable={true}
                        // renderValue={(selected) => (selected)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </ArgonBox>
            )}
          </ArgonBox>
        </>
      ) : null}
      {hasStatistics && (
        <ArgonBox display="flex" flexDirection="row" justifyContent="start" px={2}>
          <ArgonTypography
            sx={{
              color: "var(--Label, #8392AB)",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            {statValues.label}
          </ArgonTypography>
          <ArgonTypography
            sx={{
              color: "var(--Text, #1A1D1F)",
              fontSize: 20,
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
            ml={1}
          >
            {statValues.value}
          </ArgonTypography>
        </ArgonBox>
      )}

      <TableContainer
        sx={{
          boxShadow: "none",
          paddingLeft: "10px",
          // paddingRight: "10px"
        }}
        className="custom-container"
      >
        <div className={lastFixed ? "fixed-col" : ""} >
          <Table {...getTableProps()}>
            <ArgonBox component="thead">
              {headerGroups.map((headerGroup, key) => (
                <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <DataTableHeadCell
                      key={index}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      {...getToggleSortProps(column)}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                      sortedIcon={column.render("Header") === "action" ? false : true}
                      style={{
                        opacity: 1,
                        position: "relative",
                      }}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </ArgonBox>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, key) => {
                prepareRow(row);
                return clickableRecords ? (
                  <TableRow
                    key={key}
                    {...row.getRowProps()}
                    onClick={() => viewEntry(row.original.id)}
                    sx={{ cursor: "pointer" }}
                    className="clickable"
                  >
                    {row.cells.map((cell, index) => (
                      <DataTableBodyCell
                        key={cell.row.id}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                ) : (
                  <TableRow key={key} {...row.getRowProps()}>
                    {row.cells.map((cell, index) => (
                      <DataTableBodyCell
                        key={cell.row.id}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        <ArgonBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="end"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {/* {showTotalEntries && (
            <ArgonBox mb={{ xs: 3, sm: 0 }} px={2} sx={{ display: "flex" }}>
              <ArgonBox sx={{ display: "flex", alignItems: "center" }} mr={1}>
                <ArgonTypography variant="button" color="secondary" fontWeight="regular">
                  {entriesStart} to {entriesEnd} of {totalEntries} entries
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          )} */}

          {pageOptions.length > 1 && (
            <ArgonPagination
              variant={pagination.variant ? pagination.variant : "contained"}
              color={pagination.color ? pagination.color : "info"}
            >
              <ArgonPagination item onClick={() => setPageNo(pageNo - 1)} disabled={pageNo === 1}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </ArgonPagination>
              {renderPagination}
              <ArgonPagination
                item
                onClick={() => setPageNo(pageNo + 1)}
                disabled={pageNo === totalPages}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </ArgonPagination>
            </ArgonPagination>
          )}
        </ArgonBox>
      </TableContainer>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [10, 20, 30, 40, 50] },
  showEntriesPerPage: true,
  hasTabHeader: false,
  tabList: [],
  canSearch: false,
  hasStatistics: false,
  filters: [],
  showTotalEntries: true,
  pagination: { variant: "contained", color: "info" },
  isSorted: true,
  noEndBorder: false,
  dataTableName: "",
  canFilter: false,
  dateFilter: false,
  lastFixed: true,
  clickableRecords: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.number,
  ]),
  showEntriesPerPage: PropTypes.bool,
  hasTabHeader: PropTypes.bool,
  tabList: PropTypes.array,
  onTabChangeValue: PropTypes.func,
  canSearch: PropTypes.bool,
  hasStatistics: PropTypes.bool,
  statValues: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
    })
  ),
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  row: PropTypes.shape({
    getRowProps: PropTypes.func,
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        getCellProps: PropTypes.func,
        render: PropTypes.func,
      })
    ),
  }),
  dataTableName: PropTypes.string,
  canExportCSV: PropTypes.any,
  canAdd: PropTypes.any,
  canFilter: PropTypes.bool,
  dateFilter: PropTypes.bool,
  onDateRangeChange: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      listOfOptions: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  totalEntries: PropTypes.number,
  pageNo: PropTypes.number,
  setPageNo: PropTypes.func,
  totalPages: PropTypes.number,
  spinner: PropTypes.bool,
  setEntriesPerPage: PropTypes.func,
  selectedOption: PropTypes.string,
  setSearch: PropTypes.func,
  search: PropTypes.any,
  lastFixed: PropTypes.bool,
  viewEntry: PropTypes.func,
  clickableRecords: PropTypes.bool,
};

DataTableBodyCell.propTypes = {
  noEndBorder: PropTypes.bool,
  align: PropTypes.string, // Add this line
  cell: PropTypes.shape({
    column: PropTypes.shape({
      align: PropTypes.string, // Add this line
    }),
  }).isRequired,
};
export default DataTable;
