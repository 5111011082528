import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useFeedbackListingService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    const feedbacks = async (currentPage, itemsPerPage, query = "", status = "", country = "", clinic_id = "", dateRange = "") => {
        try {
            const encodedQuery = encodeURIComponent(query)
            const response = await axiosRequest.get(`/administrator_apis/feedbacks?page=${currentPage}&items_per_page=${itemsPerPage}&query=${encodedQuery}&country=${country}&clinic_id=${clinic_id}&date_range=${dateRange}&complete=${status}`);
            const feedbacksData = response.data.feedbacks;
            const feedbacksMeta = response.data.meta;
            return { feedbacksData, feedbacksMeta };
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const countries = async () => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/feedbacks/countries`);
            const countries = response;
            return { countries };
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const clinics = async () => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/feedbacks/clinics`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };
    return {
        feedbacks,
        countries,
        clinics
    };
};

export default useFeedbackListingService;
